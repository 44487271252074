<template>
  <div>
    <b-modal
      ref="add-devices-group-1"
      centered
      hide-footer
      hide-header
      class="position-relative"
    >
      <div class="add-device-group d-flex justify-content-center align-content-center align-items-center modal1 mb-2">
        <b-row
          class="d-block"
        >
          <div class="closediv">
            <b-button
              variant="transparent"
              class="closebtn"
              @click="hide()"
            >
              <feather-icon
                icon="XIcon"
                class="cursor-pointer"
                size="16"
              />
            </b-button>
          </div>
          <div class="d-inline-block d-flex justify-content-center align-content-center align-items-center">
            <span class="font-weight-bolder heading1">
              Add Screen Group
            </span>
          </div>
          <div class="d-inline-block m-1 d-flex justify-content-center align-content-center align-items-center m-1">
            <div class="form">
              <validation-observer ref="addProfileForm">
                <b-form
                  class="mt-2"
                  @submit.prevent
                >
                  <b-col class="mb-1">
                    <b-form-group
                      text-bolder
                      label="Screen Group Name"
                      class="label"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Screens Group Name"
                        rules="required"
                      >
                        <b-form-input
                          id="input-1"
                          v-model="userDevicesGroupName"
                          placeholder="Enter Screen Group Name"
                          trim
                          class="input1"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col class="mb-2">
                    <b-form-group
                      text-bolder
                      label="Screens"
                      class="label"
                    >
                      <v-select
                        v-model="selectedDeviceForGroup"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        multiple
                        select-size="3"
                        class="search"
                        label="name"
                        :options="filteredDevices"
                        @search="onSearch"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- submit buttons -->
                  <div
                    class="form-group block mb-2 mx-2"
                  >
                    <b-button
                      block
                      variant="primary"
                      :disabled="spinner"
                      type="submit"
                      @click="addGroup($event)"
                    >
                      <div
                        v-if="spinner"
                        class="spinner"
                      >
                        <b-spinner
                          small
                        />
                        <span class="sr-only">Loading...</span>
                      </div>
                      Add
                    </b-button>
                  </div>
                </b-form>
              </validation-observer>
            </div>
          </div>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  VBModal,
  BRow,
  BCol,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import { showErrorMessage, showToast } from '@/common/global/functions'

export default {
  components: {
    vSelect,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BModal,
    BRow,
    BCol,
    BSpinner,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  // eslint-disable-next-line vue/require-prop-types
  data() {
    return {
      userDevicesGroupName: '',
      spinner: false,
      selectedDeviceForGroup: [],
      selectedId: [],
      search: '',
      allDevices: [],
      // validation
      required,
    }
  },
  computed: {
    filteredDevices() {
      return this.allDevices.filter(n => !this.selectedDeviceForGroup.map(device => device.id).includes(n.id))
    },
  },
  methods: {
    getAllDevices() {
      this.$axios.get(`user/device?offset=${0}&limit=${20}&search_query=${this.search}`)
        .then(({
          data: {
            data,
          },
        }) => {
          this.allDevices = data.results
        }).catch(({
          response: {
            data: {
              statusCode, message,
            },
          },
        }) => {
          if (showErrorMessage(statusCode)) {
            showToast('All Devices', message.toString(), 'danger')
          }
        })
    },
    resetDataWhenModalClosed() {
      this.userDevicesGroupName = ''
      this.selectedDeviceForGroup = []
    },
    async onSearch(query, loading) {
      this.search = query
      if (this.search.length) {
        loading(true)
        await this.getAllDevices()
        loading(false)
      } else {
        loading(false)
      }
    },
    show() {
      this.getAllDevices()
      this.$refs['add-devices-group-1'].show()
    },
    hide() {
      this.resetDataWhenModalClosed()
      this.$refs['add-devices-group-1'].hide()
    },
    addGroup() {
      this.$refs.addProfileForm.validate().then(async success => {
        if (success) {
          this.spinner = true
          if (!this.selectedDeviceForGroup.length) {
            showToast('Add Group', 'Please select devices for group!')
            this.spinner = false
          } else {
            await this.addDevicesGroup()
            this.spinner = false
            await this.hide()
            this.userDevicesGroupName = ''
          }
        }
      })
    },
    async addDevicesGroup() {
      this.selectedId = this.selectedDeviceForGroup.map(m => m.id)
      const data = {
        name: this.userDevicesGroupName,
        device_has_group: this.selectedId,
      }
      try {
        await this.$axios.post('groups', data)
        await this.$emit('getAllDevicesUpdated')
        await this.$emit('getAllGroups')
        showToast('Devices Group', 'Devices Group has been added successfully!')
      } catch ({
        response: {
          data: {
            statusCode, message,
          },
        },
      }) {
        if (showErrorMessage(statusCode)) {
          showToast('Devices Group', message.toString(), 'danger')
        }
      }
    },
  },
}
</script>
<style src="vue-it-bigger/dist/vue-it-bigger.min.css"></style>
<style lang="scss">
.add-device-group {
  .search {
    .vs__dropdown-menu {
      max-height: 80px !important;
      overflow-y: auto !important;
      width: 320px !important;
    }
    .vs__dropdown-toggle{
      max-height: 60px !important;
      overflow-y: auto !important;
      width: 320px !important;
    }
  }
}
.form{
  padding-inline: 10px;
}
.add{
  position: relative;
}
.upload{
  position: absolute;
  bottom: 20px;
  right: 54px;
}
.head{
  font-size: 14px;
  line-height: 24px;
  color: #1f58b5;
  font-weight: 600;
  font-family: "Montserrat";
}
.spinner{
  text-align: center;
  z-index: 1070;
}
.compaignlabel{
  font-size: 10px;
  line-height: 18px;
  color: #323232;
  font-weight: 600;
  font-family: "Montserrat";
}
//.search{
//  height: 42px !important;
//  width: 320px !important;
//}
//.vs__search, .vs__search:focus{
//  height:36px !important;
//}
</style>
